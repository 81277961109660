@media screen and (min-width: 1200px) {
  .rightPanelContainer {
    margin-top: 27%;
  }
  .rightPanel {
    position: relative;
  }
}
@media screen and (max-width: 1199px) {
  .rightPanelContainer {
    margin-top: 5%;
    min-height: 90vh;
  }
}
.line {
  width: 9.5rem;
  height: 0;
  border: 1px solid #a3a6b1;
  margin-top: 3%;
  margin-left: 5%;
  margin-right: 5%;
  display: inline-block;
}
.rightPanelHeading {
  margin-top: 15%;
  margin-left: 4.5%;
  font-weight: 900;
}
.rightPanelHeaderLogo {
  margin-left: 4.5%;
  zoom: 115%;
}
.rightPanelFooterContents {
  color: #005f9e;
}
.textAlignCenter {
  text-align: center;
}
.rightPanelFooterCopyrights {
  color: #6a6976;
}
.failureLogo {
  margin-top: 4%;
  zoom: 150%;
}
.leftPanelBg {
  background-image: url('../assets/TrimbleBG.png');
  background-repeat: no-repeat;
  background-size: cover;
  left: 0%;
  right: 34.55%;
  top: 0%;
  bottom: 0%;
}
.leftPanelFooterLogo {
  margin-top: 66.5%;
  margin-left: 4.5%;
  zoom: 115%;
}
.userCodeMainDiv {
  margin-top: '4%';
}
.userCodeHeader {
  font-weight: 500;
}
.thematicOR {
  display: inline-block;
}
.newUserDiv {
  margin-left: 4.5%;
}
.newUserSpan {
  color: #005f9e;
  font-weight: bold;
}
.routeNotFoundDiv {
  font-weight: 800;
}

@media screen and (min-width: 768px) {
  .rightPanelHeading {
    margin-top: 5%;
    margin-left: 4.5%;
    font-weight: 900;
  }
}
/* for xs - extra small screen devices */
@media screen and (max-width: 281px) {
  @media screen and (max-height: 654px) {
    .userCodeInputDiv {
      width: 100vw;
    }
    .line {
      margin-top: 4%;
      width: 7.5rem;
    }
  }
}
/* Nest Hub */
@media only screen and (min-width: 1024px) and (min-height: 600px) and (orientation: landscape) {
  .failureLogo {
    margin-top: 2%;
    zoom: 100%;
  }
  .backToAuthBtn,
  .retryTextDiv {
    margin-top: 1rem !important;
  }
  .footerDiv {
    margin-top: 0.5rem;
    position: absolute;
    bottom: 0%;
  }
}
/* iPhone SE */
@media only screen and (min-width: 375px) and (orientation: portrait) {
  .footerDiv {
    position: absolute;
    bottom: 0;
    width: 92%;
  }
}
/* iPhone XR */
@media only screen and (min-width: 414px) and (orientation: portrait) {
  .footerDiv {
    position: absolute;
    bottom: 0;
    width: 92%;
  }
}
@media only screen and (min-width: 896px) and (orientation: landscape) {
  .rightPanelHeading {
    margin-top: 1rem;
  }
  .rightPanelHeaderLogo,
  .notFoundCode,
  .notFoundDiv,
  .footerDiv {
    margin-top: 0.4rem;
  }
}
/* Samsung Galaxy s8+ */
@media only screen and (min-width: 360px) and (orientation: portrait) {
  .footerDiv {
    position: absolute;
    bottom: 0;
    width: 92%;
  }
}
/* iPhone 12 Pro */
@media only screen and (min-width: 844px) and (orientation: landscape) {
  .footerDiv {
    margin-top: 2rem !important;
  }
}
/* iPad mini */
@media only screen and (min-width: 768px) and (orientation: portrait) {
  .rightPanelContainer {
    margin-top: 25%;
    min-height: 80vh;
  }
  .footerDiv {
    position: absolute;
    bottom: 20%;
    width: 92%;
  }
}
@media only screen and (min-width: 1024px) and (orientation: landscape) {
  .footerDiv {
    position: absolute;
    bottom: 3%;
    width: 92%;
  }
}
/* Surface Pro 7 */
@media only screen and (min-width: 912px) and (orientation: portrait) {
  .rightPanelContainer {
    margin-top: 25%;
    min-height: 80vh;
  }
  .footerDiv {
    position: absolute;
    bottom: 10%;
    width: 78%;
  }
}
@media only screen and (min-width: 1368px) and (orientation: landscape) {
  .footerDiv {
    position: absolute;
    bottom: 0%;
    width: 92%;
  }
}
/* Galaxy Fold */
@media only screen and (min-width: 280px) and (orientation: portrait) {
  .failureLogo {
    zoom: 100%;
  }
  .backToAuthBtn,
  .retryTextDiv {
    margin-top: 0.5rem !important;
  }
  .footerDiv {
    margin-top: 0.5rem !important;
    position: absolute;
    bottom: -6%;
  }
}
/* iPad air */
@media only screen and (min-width: 820px) and (orientation: portrait) {
  .rightPanelContainer {
    margin-top: 25%;
    min-height: 80vh;
  }
  .footerDiv {
    position: absolute;
    bottom: 2%;
    width: 84%;
  }
}
@media only screen and (min-width: 1180px) and (orientation: landscape) {
  .footerDiv {
    position: absolute;
    bottom: 0%;
    width: 80%;
  }
}
